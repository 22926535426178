import {useState} from "react";


export function MessageInput(props) {

    const [message, setMessage] = useState('');
    const [isSb, setIsSb] = useState(false)
    const {onSend} = props;
    const sendMessage = () => {
        onSend(message, isSb)
        setMessage('')
        setIsSb(false)
    };
    return <div className="container">
        <div className="card mb-4">
            <div className="card-header">
                <h4 className="my-0 font-weight-normal">发送消息</h4>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">消息内容</label>
                    <textarea className="form-95" id="exampleFormControlTextarea1" rows="3"
                              value={message}
                              onChange={(event) => setMessage(event.target.value)}
                    >

                    </textarea>
                </div>
                <div className="form-group row">
                    <div className="col-sm-3">Check it if you are 傻逼 <img src={require('./sb.png')} alt=""/></div>
                    <div className="col-sm-9">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="gridCheck1" checked={isSb}
                                   onClick={() => setIsSb(!isSb)}
                            />
                            <label className="form-check-label" htmlFor="gridCheck1">
                                Yes, I am 傻逼
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-10">
                        <button type="button" onClick={sendMessage} className="btn btn-primary"><img
                            src={require('./send.png')} alt=""/> 发送
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}