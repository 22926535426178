import {Component} from "react";

export class MessageList extends Component {
    render() {

        const {list} = this.props;
        return <div className="container">
            <div className="card mb-4">
                <div className="card-header">
                    <h4 className="my-0 font-weight-normal">发了啥</h4>
                </div>
                <div className="message-list-inner" style={{maxHeight: '50vh'}}>
                    <div className="card-body">
                        {Object.keys(list).reverse().map(key => <div className="mb-4" key={key}>
                            {list[key].isSb && <img width={24} height={24} src={require('./sb.png')} alt=""/>}
                            &nbsp;{list[key].content}
                            <div>
                                <img width={24} height={24} src={require('./time.png')} alt=""/>
                                &nbsp;  {(new Date(list[key].createdTime)).toLocaleDateString()} {(new Date(list[key].createdTime)).toLocaleTimeString()}
                            </div>
                            <div>
                                <img width={24} height={24} src={require('./man.png')} alt=""/> &nbsp; {list[key].from}
                            </div>

                        </div>)}
                    </div>
                </div>
            </div>
        </div>;
    }
}