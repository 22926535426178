import './App.css';
import {MessageInput} from "./MessageInput/index";
import {MessageList} from "./MessageList/index";
import {useEffect, useState} from "react";
import {getDatabase, ref, push, onValue} from "firebase/database";
import {serverTimestamp} from 'firebase/database'


function App() {
    const [list, setList] = useState([]);
    const [user, setUser] = useState(null);
    const [tempUser, setTempUser] = useState(null);

    const db = getDatabase();
    const chatREf = ref(db, 'chats');

    useEffect(() => {

        onValue(chatREf, (snapshot) => {
            const data = snapshot.val();
            console.log(data)
            setList(data || {})
        });

        /** handleWidgets */
    }, []);
    const onSend = (content, isSb) => {
        console.log(content)
        const payload = {
            content,
            createdTime: serverTimestamp(),
            from: user,
            isSb
        }
        push(chatREf, payload)

    };
    return (<div className="App">
        <header className="App-header">

        </header>
        {!user && <>
            <div className="card">
                <div className="card-header">
                    <h4 className="my-0 font-weight-normal">Login</h4>
                </div>
                <div className="card-body">
                    <div className="form-group row">
                        <label htmlFor="inputUser" className="col-sm-2 col-form-label"><img
                            src={require('./assets/lock.png')}/> User</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-95" id="inputUser"
                                   onKeyPress={event => event.charCode == 13 && setUser(event.target.value)}
                                   onChange={event => setTempUser(event.target.value)}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-sm-10">
                            <button type="button"
                                    onClick={() => setUser(tempUser)} className="btn btn-primary">Sign
                                in
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
        }
        {user && <>
            <MessageList list={list}></MessageList>
            <MessageInput onSend={onSend}></MessageInput>
        </>}
    </div>);
}

export default App;
